<script setup lang="ts">
import { apiClient } from "@netgame/openapi";
import dayjs from "dayjs";
import { toast } from "vue3-toastify";

import type { Badge } from "@/types";
const { open } = useFunrizeModals();
const { t } = useT();

const props = defineProps<{
	available: boolean;
	availableFrom: number;
	availableTill: number;
	subscriptionStatus?: string;
	badge: Badge;
	subscribed: boolean;
	presetGroupId: number;
	emailConfirmed: boolean;
}>();
const emit = defineEmits<{ (event: "click"): void; (event: "updatePromotion"): void }>();
const isRushHoursAvailable = ref(false);

const { ready: readyAnimation, start: handleStartAnimation } = useTimeout(2000, { controls: true, immediate: false });
const { ready: readyPopper, start: handleStartPopper } = useTimeout(2000, { controls: true, immediate: false });

const isShowAvailable = computed(() => props.availableFrom && props.availableTill);
isRushHoursAvailable.value = !!(props.availableFrom && new Date().getTime() >= props.availableFrom * 1000);
const isUserInvolved = computed(() => isRushHoursAvailable.value && props.subscriptionStatus === "involved");

const timerTime = computed(() => {
	if (!isShowAvailable.value) {
		return "";
	}
	return isRushHoursAvailable.value ? Number(props.availableTill) * 1000 : Number(props.availableFrom) * 1000;
});

const { durationLeft: rushHourTime, duration, reset } = useCountdown({ timestamp: timerTime.value });

const durationRushHourOffers = computed(() =>
	isShowAvailable.value ? Math.floor((props.availableTill - props.availableFrom) / 3600).toString() : ""
);

const availableDateForPromo = computed(() => {
	if (!isShowAvailable.value) {
		return "";
	}

	const dayOfWeek = dayjs.unix(props.availableFrom).format("dddd");
	const startTime = dayjs.unix(props.availableFrom).format("h:mm");
	const endTime = dayjs.unix(props.availableTill).format("h:mm A");

	return `${t("Available on")} ${dayOfWeek}, ${startTime}-${endTime}`;
});
const [flipped, handleToggleAnimation] = useToggle();

const subscribeToPromoGroup = async () => {
	const response = await apiClient({
		path: "/rest/cash/subscribe-to-group/",
		method: "post",
		parameters: { body: { presetsGroupId: props.presetGroupId } }
	});

	if (response.success) {
		if (response.status === "subscribed" && !isRushHoursAvailable.value) {
			toast.success(t("We will send you an email when offer is available."), {
				containerId: "promo-cards",
				icon: h("i", { class: "toast-icon icon-checked" }),
				theme: toast.THEME.DARK,
				position: toast.POSITION.BOTTOM_CENTER,
				transition: toast.TRANSITIONS.SLIDE,
				autoClose: 3000
			});
		}
		emit("updatePromotion");
	}
};

const handleClick = () => {
	if (!isRushHoursAvailable.value && props.subscribed) {
		return;
	}

	if (!props.available || !props.emailConfirmed) {
		handleStartPopper();
		return;
	}

	if (isRushHoursAvailable.value && isUserInvolved.value) {
		emit("click");
		handleToggleAnimation();
	} else {
		subscribeToPromoGroup();
	}

	handleStartAnimation();
};

const handleClickVerify = () => {
	open("LazyOModalEmailConfirm", { immediateRequest: true });
};

watch(duration, (value) => {
	if (Number(value) < 1) {
		setTimeout(() => {
			emit("updatePromotion");
			isRushHoursAvailable.value = !!(props.availableFrom && new Date().getTime() >= props.availableFrom * 1000);
			reset(timerTime.value);
		});
	}
});
</script>

<template>
	<AAnimationFlipCard :flip="isUserInvolved ? true : flipped" class="flip-card">
		<template #front>
			<ACard
				:imgAttrs="{
					width: 328,
					height: 257,
					src: `/nuxt-img/promotions/rush-hours.jpg`,
					alt: 'card-rush-front'
				}"
				height="257px"
				@click="handleClick"
			>
				<template #default>
					<ACornerBadge v-if="badge.percent && badge.text" variant="extra" modifiers="right" class="top-right">
						<AText v-if="badge.percent" variant="topeka" :modifiers="['bold']">{{ badge.percent }}</AText>
						<AText v-if="badge.text" variant="ternopil" :modifiers="['uppercase', 'normal']">{{ badge.text }}</AText>
					</ACornerBadge>
				</template>

				<template #top>
					<div class="block">
						<AText v-if="duration > 0" variant="ternopil" :modifiers="['normal', 'uppercase']" class="title">
							{{ isRushHoursAvailable ? t("Available for") : t("Available in") }} {{ rushHourTime }}
						</AText>
						<AText variant="topeka" :modifiers="['bold', 'uppercase']" class="sub-title">
							🔥 <em>{{ durationRushHourOffers }} {{ t("hours only!") }}</em>
						</AText>
					</div>
				</template>

				<template #bottom>
					<APopper v-if="available" placement="top" :show="!readyPopper">
						<AButton
							variant="primary"
							class="promo"
							data-tid="promo-rush-hour"
							:class="{ 'no-drop': !isRushHoursAvailable && subscribed }"
						>
							<AText variant="toledo" :modifiers="['semibold', 'uppercase']">
								{{ isRushHoursAvailable ? t("Rush into") : t("Ping when starts") }}
							</AText>
						</AButton>
						<template #content>
							<div @click="handleClickVerify">
								<i18n-t keypath="Please verify your email. {link}">
									<template #link>
										<AText :modifiers="['link']">{{ t("Verify now") }}</AText>
									</template>
								</i18n-t>
							</div>
						</template>
					</APopper>
					<APopper v-else class="btn-not-allowed" :content="availableDateForPromo" placement="top" :show="!readyPopper">
						<AButton variant="disabled" data-tid="promo-rush-hour-not-allowed">
							<AAnimationTada :active="!readyAnimation">
								<AText variant="tulsa">
									<NuxtIcon name="20/lock" filled />
								</AText>
							</AAnimationTada>
							<AText variant="toledo" :modifiers="['semibold', 'uppercase']">{{ t("Rush into") }}</AText>
						</AButton>
					</APopper>
				</template>
			</ACard>
		</template>
		<template #back>
			<ACard
				:imgAttrs="{
					width: 328,
					height: 257,
					src: `/nuxt-img/promotions/rush-hours-on.jpg`,
					alt: 'card-rush-back'
				}"
				height="257px"
				@click="handleClick"
			>
				<template #default>
					<ACornerBadge v-if="badge.percent && badge.text" variant="extra" modifiers="right" class="top-right">
						<AText v-if="badge.percent" variant="topeka" :modifiers="['bold']">{{ badge.percent }}</AText>
						<AText v-if="badge.text" variant="ternopil" :modifiers="['uppercase', 'normal']">{{ badge.text }}</AText>
					</ACornerBadge>
				</template>

				<template #top />

				<template #bottom>
					<APopper placement="top">
						<AButton variant="primary" class="promo" data-tid="promo-explore-offers">
							<AText variant="toledo" :modifiers="['semibold', 'uppercase']">{{ t("Explore offers") }}</AText>
						</AButton>
					</APopper>
				</template>
			</ACard>
		</template>
	</AAnimationFlipCard>
</template>

<style scoped lang="scss">
.no-drop {
	cursor: no-drop;
	opacity: 0.6;
}

.flip-card {
	width: 328px;
	height: 257px;
}

.top-left,
.top-right {
	position: absolute;
	top: 0;
	left: 0;
}

.top-right {
	left: auto;
	right: 0;
}

button {
	width: 240px;
	height: 40px;
	gap: gutter(0.75);
}

.top .nuxt-icon {
	margin-right: gutter(0.5);
}

.card {
	&:deep(.top),
	&:deep(.bottom) {
		align-items: flex-start;
	}

	.title {
		position: absolute;
		top: 42px;
		left: 20px;
		max-width: 100%;
	}

	.sub-title {
		display: block;
		min-height: 15px;
		margin-bottom: gutter(0.5);
	}
}
</style>
